import { createRouter, createWebHistory } from "vue-router";
import { createSSRApp } from "vue";
const routes = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/home",
    name: "Home",
    component: () => import("../views/Home.vue"),
  },
  {
    path: "/basketball/login",
    name: "Login from basketball",
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/basketball/signup",
    name: "SignUp from basketball",
    component: () => import("../views/SignUp.vue"),
  },
  {
    path: "/basketball",
    name: "Accueil basketball",
    component: () => import("../views/Accueil.vue"),
  },
  {
    path: "/basketball/actualite",
    name: "Actualite basketball",
    component: () => import("../views/Actualite.vue"),
  },
  {
    path: "/basketball/club",
    name: "Club basketball",
    component: () => import("../views/Club.vue"),
  },
  {
    path: "/basketball/calendrierresultat",
    name: "Calendrier/Resultat basketball",
    component: () => import("../views/CalendrierResultat.vue"),
  },
  {
    path: "/basketball/contact",
    name: "Nous contacter basketball",
    component: () => import("../views/Contact.vue"),
  },
  {
    path: "/basketball/admin",
    name: "admin",
    component: () => import("../views/Admin.vue"),
  },
  {
    path: "/basketball/match/:id",
    name: "match basketball",
    component: () => import("../views/MatchDetail.vue"),
    props: true,
  },
  {
    path: "/basketball/ActualiteDetail/:id",
    name: "ActualiteDetail basketball",
    component: () => import("../views/ActualiteDetail.vue"),
  },
  {
    path: "/basketball/EditActualite/:id",
    name: "EditActualite basket",
    component: () => import("../views/EditActualite.vue"),
  },
  {
    path: "/basketball/nouveau-article",
    name: "Nouveau article basket",
    component: () => import("../views/EditActualite.vue"),
  },
  {
    path: "/basketball/nouveau-match",
    name: "Nouveau match basketball",
    component: () => import("../views/EditMatch.vue"),
  },
  {
    path: "/basketball/edit-match/:id",
    name: "Edit match basketball",
    component: () => import("../views/EditMatch.vue"),
  },
  {
    path: "/basketball/recrutement",
    name: "Recrutement basketball",
    component: () => import("../views/Recrutement.vue"),
  },
  {
    path: "/football",
    name: "Accueil football",
    component: () => import("../views/Accueil.vue"),
  },
  {
    path: "/football/login",
    name: "Login from football",
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/football/signup",
    name: "Signup from football",
    component: () => import("../views/SignUp.vue"),
  },
  {
    path: "/football/actualite",
    name: "Actualite football",
    component: () => import("../views/Actualite.vue"),
  },
  {
    path: "/football/club",
    name: "Club football",
    component: () => import("../views/Club.vue"),
  },
  {
    path: "/football/calendrierresultat",
    name: "Calendrier/Resultat football",
    component: () => import("../views/CalendrierResultat.vue"),
  },
  {
    path: "/football/contact",
    name: "Nous contacter football",
    component: () => import("../views/Contact.vue"),
  },
  {
    path: "/football/ActualiteDetail/:id",
    name: "ActualiteDetail football",
    component: () => import("../views/ActualiteDetail.vue"),
  },
  {
    path: "/football/EditActualite/:id",
    name: "Edit News",
    component: () => import("../views/EditActualite.vue"),
  },

  {
    path: "/football/nouveau-article",
    name: "Nouveau article football",
    component: () => import("../views/EditActualite.vue"),
  },
  {
    path: "/football/nouveau-match",
    name: "Nouveau match football",
    component: () => import("../views/EditMatch.vue"),
  },
  {
    path: "/football/edit-match/:id",
    name: "Edit match football",
    component: () => import("../views/EditMatch.vue"),
  },

  {
    path: "/football/match/:id",
    name: "football match",
    component: () => import("../views/MatchDetail.vue"),
    props: true,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes: routes,
});
/*
router.beforeEach((to, from, next) => {
  let documentTitle = `${process.env.VUE_APP_TITLE} - ${to.name}`;
  if(to.params.title){

  }
  next();
  from.meta.
});*/

async function updateMetaTags(req, res) {
  // Get and parse products array from app src
  const productsSrc = `${__dirname}/client/src/products.js`;
  const productsText = await fs.promises.readFile(productsSrc);
  const productsArr = JSON.parse(productsText);

  // Retrieve product object that includes the current URL item id
  const productID = (req.originalUrl.match(/\d{9}/) || [])[0];
  const productObj = productsArr.find((prod) => prod.id == productID);

  // Update the meta tag properties in the built bundle w/ Cheerio
  const baseSrc = `${__dirname}/client//dist/index.html`;
  const baseHTML = await fs.promises.readFile(baseSrc);
  const $base = $(baseHTML);
  const $url = $base.find("meta[property=og\\:url]");
  const $title = $base.find("meta[property=og\\:title]");
  const $image = $base.find("meta[property=og\\:image]");
  const $desc = $base.find("meta[property=og\\:description]");

  $url.attr("content", `https://${req.get("host")}${req.originalUrl}`);
  $title.attr("content", productObj.title);
  $image.attr("content", productObj.ogImage);
  $desc.attr("content", productObj.description);

  // Send the modified HTML as the response
  res.send($.html($base));
}

export default router;
